import React, { useState } from 'react';
import Slider from "react-rangeslider";
import classes from "./AnticipatedIncomeComp/AnticipatedIncomeComp.module.scss";
import 'react-rangeslider/lib/index.css'
import { setInflation } from "../../../store/actions/aboutYou";
import { connect } from "react-redux";

const InflationComp = ( props ) => {
    const [ value, setValue ] = useState( props.inflation || 1.75);
    const horizontalLabels = {
        4: `4.0%`,
        0: `0%`
    }

    const onChangeRangeHandler = (value) => {
        const val = Number((value).toFixed(2))
        props.setInflation(val)
        setValue(val)
    }

    const format = value => parseFloat(value).toFixed(2) + '%'

    return (
        <React.Fragment>
            <Slider
                min={0}
                max={4}
                step={0.01}
                value={value}
                tooltip={false}
                handleLabel={value.toString()}
                format={format}
                labels={horizontalLabels}
                onChange={value => onChangeRangeHandler(value)}
            />
            <span className={classes.Value}>{parseFloat(value).toFixed(2) + '%'}</span>
            <div className={classes.Description}>
               Rate of inflation
            </div>
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        inflation: state.aboutYou.inflation,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInflation: val => dispatch(setInflation(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InflationComp);