import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayout from "../../../hoc/InnerLayout/InnerLayout";
import Navigation from "../../../components/Navigation/Navigation";
import { withRouter } from "react-router-dom";
import InflationComp from "../../../components/ContentRow/Step1/InflationComp";
import {
    setAboutYouSumCompleted,
} from "../../../store/actions/aboutYou";
import { connect } from "react-redux";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const Inflation = ( props ) => {
    const titles = {
        layoutTitle: 'TELL US ABOUT YOURSELF',
        sectionTitle: 'This tool uses the 10 year average rate of inflation of 1.74% for future projections.',
    }

    const title = "Inflation and retirement planning"

    useEffect(() => {
        const error = false
        props.setAboutYouSumCompleted(!error)
    }, [props])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep1={(6/7)*100}
            active={'step1'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayout
                sectionTitle={titles.sectionTitle}
                modalContent='inflation'
                pathVal={props.match.path}
            >

                <InflationComp/>

            </InnerLayout>

             <Navigation
                 isCompleted={true}
                 next={retirementCalcLinks.aboutYouSummary}
             />

        </Layout>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        setAboutYouSumCompleted: val => dispatch(setAboutYouSumCompleted(val)),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Inflation));